.cardDesign {
    height: 75% !important;
}

.hospital-label {
    position: relative;
    padding-right: 10px;
    /* Add padding to the right to create space for the icon */
}

.plus-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    /* Adjust size as needed */
    height: 18px;
    /* Adjust size as needed */
    border-radius: 50%;
    background-color: #79829c;
    /* Primary color background */
}

.plus-icon i {
    color: white;
    /* White color for the plus sign */
}
.div-plus-icon

{
    padding:  0px !important;
}
.div-plus-icon .plus-icon {
    margin: 7px !important;
}
.card-bg {
    background-color: #fff4eb !important;

}
.ql-toolbar
{
    background: aliceblue !important;
    padding: 4px !important;
}


.plus-col {
    margin: 10px 0px !important;
    text-align: left;
}

.form-check-inline {
    white-space: nowrap;
    /* Prevent wrapping of inline elements */
    margin-right: 20px;
    /* Adjust spacing between radio buttons */
}
.floating-download-icon {
    position: fixed;
    bottom: 50px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
    background-color: #f1b44c; /* Optional: Background color */
    color: white; /* Icon color */
    border-radius: 50%; /* Make it circular */
    padding: 5px 10px; /* Spacing around the icon */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
    cursor: pointer; /* Change cursor on hover */
    z-index: 1000; /* Ensure it's above other elements */
    font-size: 24px; /* Adjust icon size */
  }
  
  .floating-download-icon:hover {
    background-color: #f1734f; /* Change color on hover */
  }
  