.custom-btn-color {
    background-color: #f69430 !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    /* Optionally, set text color */
}

.foter {
    margin-bottom: -15px !important;

}