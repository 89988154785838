.ad-button {
    background-image: linear-gradient(to right, #01ABD6, #0FC5EA, #4387EA);
}
.input-group-text
{
    width: 41px !important;
}
.MuiChip-filled
{
    background-color: orange !important;
    color:white;
}
.MuiSvgIcon-root
{
    color: white !important;
}