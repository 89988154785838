.select-page-size {
    width: 70% !important;
    padding: 2px 2px 3px 2px !important;
}

.table td {
    padding: 4px !important;
    font-size: 11px;
}

.select-text {
    color: black !important;
}

.btn-card-color {
    background-color: bg-primary opacity-25 !important;
}

.hospital-label {
    position: relative;
    padding-right: 10px;
    /* Add padding to the right to create space for the icon */
}

.plus-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    /* Adjust size as needed */
    height: 18px;
    /* Adjust size as needed */
    border-radius: 50%;
    background-color: #79829c;
    /* Primary color background */
}

.plus-icon i {
    color: white;
    /* White color for the plus sign */
}

.pdf-footer {
    position: absolute;
    bottom: 15px;
    
    /* left: 20px; */
    /* right: 20px; */
    /* font-size: 10px; */
    /* text-align: center; */
    /* border-top: 1px solid gray; */
    /* padding-top: 5px; */
}