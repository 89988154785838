.txt-center {
    text-align: center;
}

.text-font {
    font-size: 20px;
    font-weight: 700;
}

.head-align {
    margin-top: 4%;
}

.cust-btn {
    background-color: #feedf0 !important;

}

.cust-i {
    color: #ff8ccb !important;

}

.select2-container {
    z-index: 1000;
    /* Adjust the z-index value as needed */
}

/* Add this to your CSS file or within a <style> tag */
@media (max-width: 576px) {

    .table td,
    .table th {
        padding: 0.5rem;
    }
}

.editor-value {
    // height: 3px !important;
    // margin-top: 9px !important;
    // margin-bottom: 1rem !important;
    // margin-top: 3px !important;
    // margin-bottom: 3px !important;
}

.report-view-col {
    width: 103% !important;
}

@media print {
    .page-header {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 1.5em;
        margin-bottom: 20px;
        background-color: white;
        border-bottom: 1px solid #ddd;
        z-index: 1000;
    }

    table {
        page-break-inside: auto;
        width: 100%;
        border-collapse: collapse;
    }

    tbody tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
}

/* Ensure the table header repeats on each page when printing */
@media print {
    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    .page-break {
        page-break-before: always;
    }
}

/* Optional: Ensure the table width fits within the printable area */
table {
    width: 100%;
    border-collapse: collapse;
}

/* Optional: Hide page-break elements in normal view */
// .page-break {
//     display: none;
// }

.page-break {
    page-break-before: always !important;
    /* or use another page break approach */
}

@media print {
    .page-break {
        page-break-before: always;
    }

    thead {
        display: table-header-group;
    }

    .print-th,
    .print-td {
        font-weight: unset !important;
        border: 1px solid black;
        /* Example styling for borders */
        // padding: 8px;
      //  width: fit-content !important;
        /* Example padding */
        padding: 1mm 0;
        font-size: 9px;
        padding-left: 6px;
    
    }
}
.printable {
    // display: none !important; /* Hide on screen */
}

@media print {
    .printable {
        display: block !important; /* Show during print */
    }
}
// @font-face {
//     font-family: 'Calibri';
//     src: url('../../assets/fonts/Calibri.ttf') format('truetype');
// }
// .calibri-font {
//     font-family: 'Calibri', sans-serif;
// }

@font-face {
    font-family: 'DejaVu Sans Condensed';
    src: url('../../assets/fonts/DejaVuSansCondensed.ttf') format('truetype');
}

@font-face {
    font-family: 'DejaVu Sans Condensed Bold';
    src: url('../../assets/fonts/DejaVuSansCondensedBold.ttf') format('truetype');
}
.dejaVu-font {
    font-family: 'DejaVu Sans Condensed', sans-serif;
}
.print-header-div
{
    width: calc(100% - 100px); /* Adjust width considering the left offset */
    margin: 0 auto; /* Centers the content horizontally */
   
}
tr{
    min-height: '30px !important';
}

body { font-family: "DejaVu Sans Condensed !important"; }