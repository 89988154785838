.userrolebtn {
    font-weight: 500 !important;
}

.select2-selection {
    // z-index: 1000;
    /* Adjust as needed */
}

.select2-selection {
    width: 100%;
    /* Or adjust accordingly */
}

.select2-selection__rendered {
    font-size: 14px; /* Adjust as needed */
    color: #333; /* Or your desired text color */
}