// 
// _table.scss
// 

.table {
  th {
    font-weight: $font-weight-bold;
    background-color: #eff2f7 !important;
  }
}

.table-dark>:not(:last-child)>:last-child>* {
  border-bottom-color: $table-dark-border-color;
}


.table-bordered {
  border: $table-border-width solid $table-border-color;
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}

.table>:not(:first-child) {
  border-top: 0;
}

.me-11 {
  margin-right: 7.9rem !important;
}

.me-10 {
  margin-right: 2.7rem !important;
}

.mt-9 {
  margin-top: 2.1rem !important;
}

.mt-12 {
  margin-top: 10% !important;
}

.grid-input {
  padding: 0.1rem 0.1rem !important;
}

.border-color {
  // border-color: black
}

.grid-back-color {
  --bs-table-bg: #f1b44c2e !important;
  // border-color: black !important;
  background-color: #f1b44c2e !important;
  color: #f1b44c;
  word-wrap: break-word;
}

// #50a5f1
.tab-font-size {
  font-size: 11px !important;
}

.input-width {
  width: 30% !important;
}

.report-th {
  height: 78px;
  text-align: center;
  vertical-align: middle;
}

.td-align {
  text-align: center;
  vertical-align: middle;
}

.status {
  // margin-right: 2px !important;
  flex: end;
}

.template-label {
  width: 13%;
}

input:-webkit-autoComplete {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  /* Change the color to match your background */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .ql-toolbar {
//   height: 42px; /* Adjust the height as needed */
// }
.avatar-size {
  height: 2.4rem !important;
  width: 2.4rem !important;
}

.pointer {
  cursor: pointer;
}

.view-font {
  font-size: 16px;
}

.report-request {
  margin-top: 1.2rem !important;
}

input.upper {
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
  /* Transform all text to uppercase visually */
}

input.caps {
  padding: 10px;
  font-size: 12px;
  text-transform: capitalize;
  /* Transform all text to uppercase visually */
}

.print-th {
  border: 1px solid #d3d3d3;
  padding: 4px;
  text-align: left;
  background-color: #f9f9f9;
  font-size: 9px !important;
  width: fit-content !important;
  font-weight: unset !important;
}

.print-td {
  border: 1px solid #d3d3d3;
  border-top: none !important;
  // padding: 1px 4px;
  // width: 20%;
  padding: 1mm 0;
  font-size: 9px;
  padding-left: 6px;
}
.print-td-fit {
  border: 1px solid #d3d3d3;
  border-top: none !important;
  padding: 1mm 0;
  font-size: 9px;
  padding-left: 6px;
   text-align: left;
}

.print-th-fit {
  border: 1px solid #d3d3d3;
  font-weight: unset !important;
  padding: 1mm 0;
  font-size: 9px;
  padding-left: 6px;
   text-align: left;
}
.print-td {
  border: 1px solid #d3d3d3;
  padding: 1px 4px;
  // width: 22% !important;
  font-size: 9px;
  // width: fit-content !important;
}

@media print {
  .print-td {
    font-size: 6px !important;
    /* Adjust as needed */
  }

  .print-th {
    font-weight: unset !important;
    border: 1px solid #d3d3d3;
    padding: 2px;
    text-align: left;
    background-color: #f9f9f9;
    font-size: 9px !important;
    /* Adjust as needed */
  }

}

.print-td1 {
  border: 1px solid #d3d3d3;
  padding: 1px 4px;
  width: 20%;
  font-size: 9px;
  margin-bottom: 0rem !important;
}

@media print {
  .print-td1 {
    font-size: 6px !important;
    margin-bottom: 0rem !important;

    /* Adjust as needed */
  }

  .print-th {
    border: 1px solid #d3d3d3;
    padding: 2px;
    text-align: left;
    background-color: #f9f9f9;
    font-size: 9px !important;
    margin-bottom: 0rem !important;
    font-weight: unset !important;
    /* Adjust as needed */
  }

}

p {
  margin-bottom: 0rem !important;
}

hr.new1 {
  border-top: 1px solid black;
}

.report-font-size {
  font-size: 10px !important;
}

.report-header-font-size {
  font-size: 12px !important;
}

.report-inner-font-size {
  font-size: 11px !important;
}

.square1-switch {
  margin-right: 7px;
}

input.switch1 {
  display: none;
}

input.switch1:checked+label:after {
  left: 105px !important;
  background-color: #eff2f7;
}

.square1-switch input.switch1+label,
.square1-switch input.switch1+label:after {
  border-radius: 4px;
}

input.switch1+label {
  font-size: 1em;
  line-height: 1;
  width: 127px;
  height: 27px;
  background-color: #f1b44c;
  background-image: none;
  border-radius: 2rem;
  padding: 0.16667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  font-weight: 500;
  transition: all 0.1s ease-in-out;
}

input.switch1+label:before {
  color: #343a40;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  right: 32px !important;
  margin: 3px;
  top: -2px;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

input.switch1+label:after {
  border-radius: 4px;
}

input.switch1+label:after {
  content: '';
  position: absolute;
  left: 3px;
  background-color: black;
  box-shadow: none;
  border-radius: 2rem;
  height: 20px;
  width: 20px;
  top: 2px;
  transition: all 0.1s ease-in-out;
}

input.switch1-bool+label:before,
input.switch1-bool:checked+label:before,
input[switch="default"]:checked+label:before {
  color: #fff;
}

input.switch1:checked+label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 3px;
}

input.switch1+label:before {
  color: #fff;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 21px;
  position: absolute;
  right: 49px !important;
  margin: 3px;
  top: -1px;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

input.switch1+label:after {
  border-radius: 4px;
}

input.switch1+label:after {
  content: '';
  position: absolute;
  left: 3px;
  background-color: #eff2f7;
  box-shadow: none;
  border-radius: 2rem;
  height: 23px;
  width: 20px;
  top: 2px;
  transition: all 0.1s ease-in-out;
}

.container1 {
  text-align: right;
  /* Aligns content to the right side */
}

.centered-image {
  display: block;
  /* Makes the image a block-level element */
  margin: 0 auto;
  /* Centers the image horizontally within the content block */
}

.content {
  display: inline-block;
  /* Ensures the content is treated as a block for text alignment */
  text-align: center;
  /* Centers the image and text within the content block */
}
.grid-table {
  display: flex;
  width: 100%;
  // grid-template-columns: 100px auto 100px auto; /* Fixed widths for columns 1 and 3, dynamic for 2 and 4 */
  border-collapse: collapse;
}

.grid-table div {
  border: 1px solid #d3d3d3;
  padding: 1px 4px;
  //width: 18% !important;
  font-size: 9px;
  flex-grow: 1;
  // width: fit-content !important;
 
  //  /white-space: nowrap; /* Prevents wrapping of content in the cell */
   text-align: left;
  // padding: 8px;
  overflow-wrap: break-word; /* Ensures content breaks to the next line if too long */
}
@font-face {
  font-family: 'DejaVu Sans Condensed';
  src: url('../../../../assets/fonts/DejaVuSansCondensed.ttf') format('truetype');
}
.dejaVu-font {
  font-family: 'DejaVu Sans Condensed', sans-serif;
}