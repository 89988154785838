//
// Datepicker
//

.datepicker {
  border: 1px solid #F1734F;
  padding: 8px;
  z-index: 999 !important;


  table {
    tr {
      th {
        font-weight: 500;
      }

      td {

        &.active,
        &.active:hover,
        .active.disabled,
        &.active.disabled:hover,
        &.today,
        &.today:hover,
        &.today.disabled,
        &.today.disabled:hover,
        &.selected,
        &.selected:hover,
        &.selected.disabled,
        &.selected.disabled:hover,
        span.active.active,
        span.active:hover.active {
          background-color: #F1734F !important;
          background-image: none;
          box-shadow: none;
          color: $white !important;
        }

        &.day.focused,
        &.day:hover,
        span.focused,
        span:hover {
          background: #F1734F;
        }

        &.new,
        &.old,
        span.new,
        span.old {
          color: #F1734F;
          opacity: 0.6;
        }

        &.range,
        &.range.disabled,
        &.range.disabled:hover,
        &.range:hover {
          background-color: #F1734F;
        }
      }
    }
  }
}

.table-condensed {

  >thead>tr>th,
  >tbody>tr>td {
    padding: 7px;
  }
}

.bootstrap-datepicker-inline {
  .datepicker-inline {
    width: auto !important;
    display: inline-block;
  }
}

.flatpickr-calendar .flatpickr-day.endRange {
  background-color: #F1734F !important;
  /* Change to your desired hover color */
  color: #000 !important;
  /* Optional: Change text color on hover */
}

.flatpickr-calendar .flatpickr-day.startRange {
  background-color: #F1734F !important;
  /* Change to your desired hover color */
  color: #000 !important;
  /* Optional: Change text color on hover */
}

// DATEPICKER

.datepicker-container {
  border: 1px solid #F1734F;
  box-shadow: none;
  background-color: #F1734F;

  &.datepicker-inline {
    width: 212px;
  }
}

.datepicker {
  color: #F1734F;

  .datepicker-switch:hover,
  .next:hover,
  .prev:hover,
  tfoot tr th:hover {
    background: #F1734F;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #F1734F !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #F1734F !important;
  }
}



.datepicker-panel {

  >ul {
    >li {
      background-color: #F1734F;
      border-radius: 4px;

      &.picked,
      &.picked:hover {
        background-color: #F1734F;
        color: #F1734F;
      }

      &.highlighted,
      &.highlighted:hover,
      &:hover {
        background-color: #F1734F;
        color: #F1734F;
      }


      &.muted,
      &.muted:hover {
        color: #F1734F;
        opacity: 0.6;
      }
    }



    &[data-view=week] {
      >li {
        font-weight: $font-weight-medium;
      }

      >li,
      >li:hover {
        background-color: #F1734F;
      }
    }
  }
}